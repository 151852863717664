import { TAB_KEY, TAB_KEYCODE } from '../constants/keyCodes';

const gatherFocusableElements = parentElement => {
  const els = parentElement.querySelectorAll(`
    #rcw-conversation-container,
    a[href]:not([disabled]):not([hidden]),
    button:not([disabled]):not([hidden]),
    textarea:not([disabled]):not([hidden]),
    input:not([disabled]):not([hidden]),
    select:not([disabled]):not([hidden])
  `);
  return [...els];
};

const getElByClass = className => {
  return document.getElementsByClassName(className)[0];
};

const hidePickerButton = () => {
  const pickerBtn = getElByClass('rcw-picker-btn');
  if (pickerBtn) {
    pickerBtn.ariaHidden = true;
    pickerBtn.hidden = true;
  }
};

const removeUnneededCardAttributes = card => {
  // remove focus on card wrapper
  card.removeAttribute('tabindex');
  // we make use of a hidden input to pass card id to jEllie. remove tabindex from this input if it exists and add hidden attributes
  const hiddenInput = card.querySelector('#card input');
  if (hiddenInput) {
    hiddenInput.hidden = true;
    hiddenInput.ariaHidden = true;
    hiddenInput.removeAttribute('tabindex');
  }
  // remove unnecessary role on submit btn wrapper divs appended by microsoft adadptive cards
  const actionSetWrappers = [...card.querySelectorAll('.ac-actionSet')];
  if (actionSetWrappers.length > 0) {
    actionSetWrappers.forEach(el => el.removeAttribute('role'));
  }
};

const setCloseButtonInfo = () => {
  const closeBtn = getElByClass('rcw-close-button');
  if (closeBtn) {
    closeBtn.ariaLabel = 'Close';
    const display = window.getComputedStyle(closeBtn).display;
    // if close btn is not visible, set the hidden attribute so it isn't deemed focusable
    if (display === 'none') {
      closeBtn.hidden = true;
      closeBtn.ariaHidden = true;
    } else {
      closeBtn.hidden = false;
      closeBtn.ariaHidden = false;
    }
  }
};

const setContainerRole = () => {
  const chatContainer = getElByClass('rcw-conversation-container');
  if (chatContainer) {
    chatContainer.setAttribute('role', 'dialog');
    chatContainer.setAttribute('tabindex', 0);
    chatContainer.ariaLabel = 'Chatbot';
  }
};

const setLogoAltText = logoLocation => {
  if (logoLocation) {
    const title = getElByClass('rcw-title');
    if (title) {
      const avatar = title.getElementsByClassName('avatar')[0];
      avatar.alt = 'CareScheduler logo';
    }
  }
};

const setNewMessageLabel = () => {
  const input = getElByClass('rcw-input');
  if (input) {
    input.ariaLabel = 'Type a message';
  }
};

const setQuickButtonA11yInfo = () => {
  const quickButtons = document.getElementsByClassName('quick-button');
  [...quickButtons].forEach(btn => (btn.ariaLabel = btn.innerText));
};

// for radio groups and checkbox groups, the containing div must have either the radiogroup (for radios) or group (for checkboxes) role
// further, the aria-required property should only be on the div with the role, and not on the individual inputs
const setRadioAndMultiselectRoles = card => {
  const radioWrappers = card.querySelectorAll('.ac-choiceSetInput-expanded');
  const checkboxWrappers = card.querySelectorAll('.ac-choiceSetInput-multiSelect');
  radioWrappers.forEach(wrap => {
    wrap.setAttribute('role', 'radiogroup');
    const inputs = wrap.querySelectorAll('input[type=radio][aria-required=true]');
    inputs.forEach(i => i.removeAttribute('aria-required'));
  });
  checkboxWrappers.forEach(wrap => {
    wrap.setAttribute('role', 'group');
    const inputs = wrap.querySelectorAll('input[type=checkbox][aria-required=true]');
    inputs.forEach(i => i.removeAttribute('aria-required'));
  });
};

const setSendButtonLabel = () => {
  const sendBtn = getElByClass('rcw-send');
  if (sendBtn) {
    sendBtn.ariaLabel = 'Send';
  }
};

const setToggleButtonInfo = isOpen => {
  const toggler = getElByClass('rcw-launcher');
  if (toggler) {
    const display = window.getComputedStyle(toggler).display;
    // if launcher btn is hidden due to close btn being visible, set appropriate a11y info so it isn't deemed focusable
    if (display === 'none' && getElByClass('rcw-close-button')) {
      toggler.hidden = true;
      toggler.ariaHidden = true;
      toggler.ariaLabel = 'Close';
      toggler.ariaExpanded = true;
    } else {
      toggler.hidden = false;
      toggler.ariaHidden = false;
      if (isOpen) {
        toggler.ariaLabel = 'Close';
        toggler.ariaExpanded = true;
      } else {
        toggler.ariaLabel = 'Open';
        toggler.ariaExpanded = false;
        toggler.focus();
      }
    }
  }
};

// ADA says that for a modal window, the focus must stay trapped in the modal. the chatbot is considered a modal.
// more info: https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element
// this is called after every time the chatbot is toggled, or an adaptive card is rendered to re-gather the focusable elements
const trapChatbotFocus = () => {
  const chatContainer = getElByClass('rcw-widget-container');
  if (chatContainer) {
    const focusableEls = gatherFocusableElements(chatContainer);

    // set custom props on the el to be used outside of this func's context...thanks prototypical inheritance
    chatContainer.firstFocusableEl = focusableEls[0];
    chatContainer.lastFocusableEl = focusableEls[focusableEls.length - 1];

    // since focusableEls change with each card render, need to reset the handler each invocation
    chatContainer.removeEventListener('keydown', tabHandler);
    chatContainer.addEventListener('keydown', tabHandler);
  }
};

const tabHandler = e => {
  const chatContainer = e.currentTarget;
  const isTabPressed = e.key === TAB_KEY || e.keyCode === TAB_KEYCODE;
  if (!isTabPressed) {
    return;
  }
  if (e.shiftKey) {
    // shift + tab on first focus el, go to last focus el
    if (document.activeElement === chatContainer.firstFocusableEl) {
      chatContainer.lastFocusableEl.focus();
      e.preventDefault();
    }
  } else {
    // tab on last focus el, go back to first
    if (document.activeElement === chatContainer.lastFocusableEl) {
      chatContainer.firstFocusableEl.focus();
      e.preventDefault();
    }
  }
};

const resetAdaptiveCardsA11y = () => {
  const allRenderedCards = [...document.getElementsByClassName('ac-adaptiveCard')];
  if (allRenderedCards.length > 0) {
    allRenderedCards.forEach(card => {
      removeUnneededCardAttributes(card);
      setRadioAndMultiselectRoles(card);
    });
  }
};

// this function is called every time chatbot changes its isOpen state (including initial load) in Chatbot.js
export const handleBotWidgetA11y = (logoLocation, isOpen) => {
  hidePickerButton();
  setCloseButtonInfo();
  setContainerRole();
  setLogoAltText(logoLocation);
  setNewMessageLabel();
  setSendButtonLabel();
  setToggleButtonInfo(isOpen);
  // if toggled in the middle of a flow, react destroys and re-creates all the adaptive cards and quick buttons
  // so, we have to reset their a11y info when it's reopened
  if (isOpen) {
    setQuickButtonA11yInfo();
    resetAdaptiveCardsA11y();
  }
  trapChatbotFocus();
};

// this function is called every time a new adaptive card is rendered in Chatbot.js
export const handleAdaptiveCardA11y = card => {
  removeUnneededCardAttributes(card);
  setRadioAndMultiselectRoles(card);
  trapChatbotFocus();
};

// this function is called every time new quick buttons are rendered in Chatbot.js
export const handleQuickButtonA11y = () => {
  setQuickButtonA11yInfo();
  trapChatbotFocus();
};
